<template>
    <div class="live_info_container">
        <div class="live_info_title_banner">
            <img :src="require('@/assets/images/live/banner_bg.png')" alt="">
        </div>
        <div class="live_info_content_block">
            <div class="live_info_content_row global_primary_text_color">
                <span class="content_lable_key">时间:</span>
                <span>{{ getMeetTimeText() }}</span>
            </div>
            <div class="live_info_content_row global_primary_text_color">
                <span class="content_lable_key">主题:</span>
                <span>{{this.meetingInfo?.class_info?.title || "暂无"}}</span>
            </div>
            <div class="live_info_content_row global_primary_text_color">
                <div class="content_lable_key">互动医生:</div>
                <div class="content_doctor_row">
                    <div>姓名:{{this.meetingInfo?.meet_info?.doctor_name || "暂无"}}</div>
                </div>
                <div>医院:{{this.meetingInfo?.meet_info?.hospital || "暂无"}}</div>
                <div>职称:{{this.meetingInfo?.meet_info?.job_name || "暂无"}}</div>
            </div>
            <div class="live_info_content_row live_info_table">
                <div class="live_info_table_title_tr">
                    <div class="live_info_table_td global_container_center live_info_table_td_title">
                        <img :src="require('@/assets/images/live/clock_icon.png')" alt="">
                        时间
                    </div>
                    <div class="live_info_table_td global_container_center live_info_table_td_title">
                        <img :src="require('@/assets/images/live/content_icon.png')" alt="">
                        内容
                    </div>
                    <div class="live_info_table_td global_container_center live_info_table_td_title">
                        <img :src="require('@/assets/images/live/doctor_icon.png')" alt="">
                        讲者
                    </div>
                </div>
                <div class="live_info_table_content_tr live_info_table_content_tr" v-for="(item,key) in liveInfoData" :key="key">
                    <div class="live_info_table_td global_container_center live_info_table_td_content global_primary_text_color">
                        {{ `${item.start_time}-${item.end_time}`}}
                    </div>
                    <div class="live_info_table_td global_container_center live_info_table_td_content global_primary_text_color">
                        {{ item.content}}
                    </div>
                    <div class="live_info_table_td global_container_center live_info_table_td_content global_primary_text_color">
                        {{ item.doctor}}
                    </div>
                </div>
            </div>
            <div class="live_info_QRCode global_primary_text_color">
                <img :src="require('@/assets/images/live/QRCode.png')" alt="">
                扫码关注项目官方公众号百展讲堂,即可报名参与,快来与专家亲密互动吧!
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'live-info',
    props:{
        meetingInfo:{
            type:Object,
            default:()=>{
                return { }
            }
        },
        isLive:{
        type:Boolean,
        default:false
        }
    },
    data(){
        return{
            liveInfoData:{
                1:{
                    start_time:"00:00",
                    end_time:"00:00",
                    content:"医生开场",
                    doctor:"doctor"
                },
                2:{
                    start_time:"00:00",
                    end_time:"00:00",
                    content:"主题视频课件观摩学习",
                    doctor:"/"
                },
                3:{
                    start_time:"00:00",
                    end_time:"00:00",
                    content:"答疑互动",
                    doctor:"doctor&患者"
                },
            },
            weeks:["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
        }
    },
    
  mounted(){
    // console.log(this.meetingInfo)
    this.initData()
  },
  methods:{
    initData(){
        if(!this.meetingInfo.meet_info) return;
        console.log(this.meetingInfo)
        for (const key in this.liveInfoData) {
            if (Object.hasOwnProperty.call(this.liveInfoData, key)) {
                const element = this.liveInfoData[key];
                element.doctor = element.doctor.replace("doctor",this.meetingInfo.meet_info.doctor_name)
            }
        }
        let firstLinkStartTime = this.$tools.getTime(this.meetingInfo.meet_info.start_time);//第一环节开始时间的时间戳
        let firstLinkEndTime = this.$tools.getTime(this.meetingInfo.meet_info.start_time)+3*60*1000;//第一环节结束时间的时间戳
        let secendLinkStartTime = firstLinkEndTime;//第二环节开始时间的时间戳
        let secendLinkEndTime = secendLinkStartTime+Number(this.meetingInfo.class_info.play_video_time)*1000;//第二环节结束时间的时间戳
        let thirdLinkStartTime = secendLinkEndTime;//第三环节开始时间的时间戳
        let thirdLinkEndTime = this.$tools.getTime(this.meetingInfo.meet_info.end_time);//第三环节结束时间的时间戳
        this.liveInfoData[1].start_time=this.$tools.getDate(firstLinkStartTime,"hh:mm");
        this.liveInfoData[1].end_time=this.$tools.getDate(firstLinkEndTime,"hh:mm");
        this.liveInfoData[2].start_time=this.$tools.getDate(secendLinkStartTime,"hh:mm");
        this.liveInfoData[2].end_time=this.$tools.getDate(secendLinkEndTime,"hh:mm");
        this.liveInfoData[3].start_time=this.$tools.getDate(thirdLinkStartTime,"hh:mm");
        this.liveInfoData[3].end_time=this.$tools.getDate(thirdLinkEndTime,"hh:mm");
     },
    getMeetTimeText(){
        if(!this.meetingInfo?.meet_info) return "暂无";
        let ymd = this.$tools.getDate(this.meetingInfo.meet_info.start_time,"yyyy年MM月dd日")
        let week = this.weeks[this.$tools.getFormatTime(this.meetingInfo.meet_info.start_time).getDay()];
        let start_time = this.$tools.getDate(this.meetingInfo.meet_info.start_time,"hh:mm")
        let end_time = this.$tools.getDate(this.meetingInfo.meet_info.end_time,"hh:mm")
        return `${ymd} ${week} ${start_time}-${end_time}`
    },
    closeQuestion(){},
    initScroll(){},
  }
}
</script>
<style>
.live_info_container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.live_info_title_banner{
    width: 100%;
}
.live_info_title_banner img{
    display: block;
    width: 100%;
    height: auto;
}
.live_info_content_block{
    width: 100%;
    flex: 1 0 0;
    background-image: url('../../../../assets/images/live/content_bg.png');
    background-size: 100% 100%;
    background-repeat:no-repeat;
    padding: 0 6vw;
}
.live_info_content_row{
    margin-top: 3.5vw;
    width: 100%;
    /* font-size: 3.5vw; */
}
.content_lable_key{
    font-weight: 600;
}
.content_doctor_row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3.5vw;
}
.live_info_table{
    border-bottom: 1px #106c3b solid;
}
.live_info_table_title_tr{
    display: flex;
    width: 100%;
    height: 10vw;
}
.live_info_table_content_tr{
    display: flex;
    width: 100%;
    align-items: center;
}
.live_info_table_content_tr:nth-child(2n-1){
    background: #b6d6bf;
}
.live_info_table_content_tr:nth-child(2n-1) .live_info_table_td_content,.live_info_table_content_tr .live_info_table_td_content:last-child{
    border-right: none;
}
.live_info_table_td_content{
    border-right: 1px #9edaba solid;
    text-align: center;
}
.live_info_table_td{
    min-height: 10vw;
    flex: 1 0 0;
    padding: 0 0.5vw;
}
.live_info_table_td_title{
    background-image: url('../../../../assets/images/live/table_td_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    font-weight: 600;
    padding: 1.8vw 0;
}
.live_info_table_td_title img{
    height: 100%;
    width: auto;
    margin-right: 2vw;
}
.live_info_QRCode{
    width: 100%;
    height: 15vh;
    padding: 2vw 0;
    display: flex;
    align-items: center;
}
.live_info_QRCode img{
    height: 100%;
    width: auto;
}
</style>